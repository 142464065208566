<script>
import { authMethods } from '@state/helpers'
import dispatchActionForAllModules from "@utils/dispatch-action-for-all-modules";
import VueCountdown from './countdown.min';
import AuthPageCard from "@components/auth/AuthPageCard";

export default {
  page: {
    title: 'Σύνδεση',
    meta: [{ name: 'description', content: `Σύνδεση στο athlisis` }],
  },
  components: {AuthPageCard, countdown: VueCountdown },
  data() {
    return {
      email: '',
      password: '',
      authError: null,
      tryingToLogIn: false,
      otp:{
        email: null,
        code: null,
        id: null,
        canResend: false,
        expires: null
      }
    }
  },
  computed:{
    isOTPValid(){
      return this.otp.code && this.otp.code.length === 4;
    }
  },
  methods: {
    ...authMethods,

    verifyOTP(){
      if(!this.isOTPValid) return false;
      this.$axios.post('/users/verify-otp', this.otp).then(res=>{
        this.loggedInActions(res.data);
      }).catch(e => {
        if(e.response.status === 429){
          this.$notify({group: 'athlisis-notifications', type:'error', duration:10000, title: 'Πολλές εσφαλμένες προσπάθειες',
            text: 'Έχετε βάλει πάνω από 5 φόρες λάθος τον 4-ψήφιο κωδικό. Παρακαλώ δοκιμάστε μετά την λήξη του τωρινού κωδικού.'});
        }else{
          this.$notify({group: 'athlisis-notifications', type:'error', duration:10000, title: 'Λάθος κωδικός',
            text: 'Λάθος 4-ψήφιος κωδικός'});
        }
      })
    },
    loggedInActions(data){
      this.$store.dispatch('auth/cacheUser', data);
      if(!this.isEducational && !data.user.isPremium) return this.$router.push({name: 'purchase-athlisis'});
      dispatchActionForAllModules('init'); // fill in caches for foods etc
          // Redirect to the originally requested page, or to the home page except if it came from logout!
      if(this.$route.query.redirectFrom && this.$route.query.redirectFrom.includes('logout')) this.$router.push({name: 'home'});
      this.$router.push(this.$route.query.redirectFrom || { name: 'home' });

    },
    resendOTP(){
      this.$axios.post('/users/resend-otp', this.otp).then(res =>{
        if(res.status === 206){
          this.otp.id = res.data.id;
          this.otp.canResend = res.data.canResend;
          this.otp.expires = res.data.validUntil;
        }
      });
    },
    login() {
      this.tryingToLogIn = true;
      // Reset the authError if it existed.
      this.authError = null;

      this.$axios.post('/users/login', {  email: this.email, password: this.password}).then(res =>{

        // NEEDS OTP
        if(res.status === 206){
          this.otp.id = res.data.id;
          this.otp.email = this.email;
          this.otp.canResend = res.data.canResend;
          this.otp.expires = res.data.validUntil;
          this.$refs['otp-modal'].show();
        }else{
          this.loggedInActions(res.data);
        }
      }).catch(e => {
        this.authError = e.response? e.response.data.message: 'Κάτι πήγε λάθος. Δοκιμάστε ξανά αργότερα!';
      }).finally(()=>{
        this.tryingToLogIn = false;
      });
    },
  },
}
</script>

<template>
  <div id="login">
    <auth-page-card subtitle="Παρακαλώ εισάγετε το e-mail σας και τον κωδικό σας για να συνδεθείτε στην εφαρμογή.">

      <template v-slot:default>
        <div>
          <b-alert v-show="authError" show variant="danger">
            {{authError}}
          </b-alert>

          <b-form @submit.prevent="login">
            <b-form-group id="input-group-1" label="E-mail" label-for="email">
              <b-form-input id="email" v-model="email" type="text" required placeholder="Εισάγετε το e-mail σας"></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-2" label="Password" label-for="password">
              <b-form-input id="password" v-model="password" type="password" required placeholder="Εισάγετε τον κωδικό σας">
              </b-form-input>
            </b-form-group>

            <b-form-group id="button-group" class="mt-4">
              <b-button type="submit" variant="primary" class="btn-block"><i class="fe-unlock"></i> Σύνδεση</b-button>
            </b-form-group>
            <p v-if="!isEducational" class="text-white-50 text-center mt-4">
              <a href="https://education.athlisis.com" class=" ml-1"><b>Σύνδεση για φοιτητές</b></a>
            </p>
          </b-form>
        </div>
      </template>

      <template v-slot:under-card>
        <div class="row mt-3">
          <div class="col-12 text-center">
            <p>
              <router-link tag="a" to="/forgot-password" class="text-white-50 ml-1">Ξεχάσατε τον κωδικό σας;</router-link>
            </p>
            <p v-if="!isEducational" class="text-white-50">Δεν έχετε λογαριασμό;
              <router-link tag="a" to="/register" class="text-white ml-1"><b>Εγγραφή</b></router-link>
            </p>

          </div>
          <!-- end col -->
        </div>
      </template>

    </auth-page-card>
    <b-modal ref="otp-modal" title="Εισαγωγή OTP" hide-footer>
      <div class="row">
        <div class="col-12">
          <p>
            Εισάγετε τον 4-ψήφιο κωδικό που έχει σταλεί στο e-mail σας.
          </p>
          <p>
            Απομένουν <strong>
            <countdown :time="otp.expires * 1000">
              <template slot-scope="props">{{ '0'+props.minutes  }}:{{ props.seconds < 10 ? '0' + props.seconds : props.seconds }}.</template>
            </countdown>
          </strong>
          </p>

          <p v-if="otp.canResend" class="font-weight-bold">
            Δεν λάβατε κωδικό; <b-button variant="link" v-if="otp.canResend" class="p-0" @click="resendOTP">εκ-νέου αποστολή κωδικού</b-button>
          </p>
        </div>
        <div class="col-12">
          <label>4-ψήφιος κωδικός:</label>
          <b-form-input v-model="otp.code" :state="isOTPValid"/>
        </div>
        <div class="col-12 mt-3 text-right">
          <b-button v-if="isOTPValid"  variant="primary"  @click="verifyOTP">Σύνδεση</b-button>
        </div>

        <div class="col-12 mt-3 text-center">
          <p style="font-size:0.9em;">Εάν αντιμετωπίζετε πρόβλημα επικοινωνήστε μαζί μας στο <a href="mailto:info@athlisis.com">info@athlisis.com</a></p>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<style lang="scss" module></style>
